<template lang="pug">
  div.wrap-set-client.py40.px40
    div.f.fc.mb20
      ItemMediaUploader(
        v-if="existingImg"
        :imgId="docId"
        :existingImg="existingImg"
        categoryName="clientIcon"
        ref="mediaUploader")
    div.wrap-inputs.mb12
      v-text-field(v-model="name" label="氏名")
      v-text-field(v-model="email" label="メールアドレス")
      v-select(v-model="goalWeight" label="目標体重" :items="goalWeightList")
      //- v-text-field(v-model="goalWeight" label="目標体重" color="#2a2a2a")
    div.wrap-submit.f.flex-right
      span(@click="$emit('closeModal')").cancel.mr16 キャンセル
      span(@click="saveClient" :class="{'is-active': saveIsActive}").save 保存

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-set-client {
  min-width: 340px;
  background: $primary_bg_color;
  border-radius: 3px;
  .wrap-submit {
    span {
      cursor: pointer;
    }
    .cancel {
      color: $secondary_text_color;
    }
    .save {
      color: #999;
      pointer-events: none;
      &.is-active {
        color: $active_color;
        pointer-events: all;
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import ItemMediaUploader from '@/components/item/ItemMediaUploader.vue'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsClient } = createNamespacedHelpers('client')

export default {
  components: {
    ItemMediaUploader
  },
  props: {
    // When edit existing client
    existingDocId: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    newDocId: () => {
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var autoId = ''
      for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return autoId
    },
    goalWeightList: () => {
      var lists = []
      for (var i = 0; i < 130; i = Number((i + 0.1).toFixed(1))) {
        lists.push(String(i))
      }
      return lists
    }
  },
  watch: {
    name: function () {
      this.saveIsActive = (this.name !== '' && this.email !== '')
    },
    email: function () {
      this.saveIsActive = (this.name !== '' && this.email !== '')
    }
  },
  data () {
    return {
      docId: null,
      existingImg: null,
      name: '',
      email: '',
      goalWeight: '',
      saveIsActive: false
    }
  },
  async created () {
    this.docId = (this.$route.params.clientId) ? this.$route.params.clientId : this.newDocId
    if (this.$route.params.clientId) {
      var client = await this.getClient(this.$route.params.clientId)
      this.existingImg = client.photoURL
      this.name = client.name
      this.email = client.email
      this.goalWeight = (client.goalWeight) ? client.goalWeight : ''
    }

    if (!this.existingImg) this.existingImg = '/img/default-user-icon.png'
  },
  methods: {
    ...mapActionsClient(['setClient', 'updateClient', 'getClient']),
    async saveClient () {
      if (!this.saveIsActive) return
      this.saveIsActive = false
      var clientObj = {
        uid: this.docId,
        name: this.name,
        email: this.email,
        goalWeight: this.goalWeight,
        photoURL: this.$refs.mediaUploader.getImgUrl(),
        // records: [],
        created: new Date(),
        updated: new Date(),
        author: this.uid
      }
      if (!this.existingDocId) clientObj.created = new Date()

      if (this.$route.params.clientId) {
        await this.updateClient(clientObj)
        this.$parent.$parent.$parent.updateClientInfo()
      } else {
        await this.setClient(clientObj)
      }

      this.$emit('closeModal', clientObj)

      mixpanel.track('onSaveClient', { createdAt: new Date() })
    }
  }
}
</script>
