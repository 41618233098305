<template lang="pug">
  transition(name="fade")
    div(v-if="showModal" @click="$emit('closeModal')").wrap-modal.f.fh
      div(@click.stop).prevent-wrap.f.fc
        //- trainer
        ModuleAddCalendarEvent(
          v-if="modalContentId === 'addCalendarEvent'"
          @closeModal="() => { $emit('closeModal') }")
        //- trainee
        ModuleSetClient(
          v-if="modalContentId === 'createClient'"
          @closeModal="() => { $emit('closeModal') }")
        ModuleSetTrainer(
          v-if="modalContentId === 'setTrainer'"
          @closeModal="() => { $emit('closeModal') }")
        ModuleSelectClient(
          v-if="modalContentId === 'selectClient'"
          @closeModal="() => { $emit('closeModal') }")
        ModuleAddScheduleEvent(
          v-if="modalContentId === 'addScheduleEvent'"
          @closeModal="() => { $emit('closeModal') }"
          :option="option")
        ModuleClientBuyProduct(
          v-if="modalContentId === 'buyProduct'"
          @closeModal="() => { $emit('closeModal') }"
          :option="option")
</template>

<style lang="scss" scoped>
.wrap-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 201;
  background: rgba(0, 0, 0, 0.4);
  overflow: scroll;
  .prevent-wrap {
    width: 90%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import ModuleAddCalendarEvent from '@/components/module/ModuleAddCalendarEvent'
import ModuleSetClient from '@/components/module/ModuleSetClient'
import ModuleSetTrainer from '@/components/module/ModuleSetTrainer'
import ModuleSelectClient from '@/components/module/ModuleSelectClient'
import ModuleAddScheduleEvent from '@/components/module/ModuleAddScheduleEvent'
import ModuleClientBuyProduct from '@/components/module/ModuleClientBuyProduct'

export default {
  components: {
    ModuleAddCalendarEvent,
    ModuleSetClient,
    ModuleSetTrainer,
    ModuleSelectClient,
    ModuleAddScheduleEvent,
    ModuleClientBuyProduct
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    modalContentId: {
      type: String,
      required: true
    },
    option: {
      type: Object,
      required: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>
