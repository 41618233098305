<template lang="pug">
  div.wrap-module-schedule-event.px20.py20
    div.mb24
      ItemAutoCompleteSelector(
        ref="clientSelector"
        v-if="clients"
        :allUsers="clients"
        placeholder="会員様を選択"
        icon="mdi-account-box-multiple"
        @callBackOnList="callBackOnList")
    v-text-field(label="タイトル" placeholder="タイトルを入力" v-model="title")
    v-textarea(label="コメント"
      placeholder="コメントを入力"
      v-model="comment"
      rows="2")
    div.date.mb24
      span.label 日付
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          span(v-on="on").input-date {{dateValue}}
        div
          v-date-picker(v-model="datePicker" color="#1a73e8")
    div(v-if="!allDay").time.mb12
      span.label 時間
      div.f.fm
        div.start
          select(v-model="startHour").selecter
            option(v-for="item in startHourSelections") {{item}}
          span :
          select(v-model="startMinute").selecter
            option(v-for="item in startMinuteSelections") {{item}}
        span.px8 ~
        div.end
          select(v-model="endHour").selecter
            option(v-for="item in endHourSelections") {{item}}
          span :
          select(v-model="endMinute").selecter
            option(v-for="item in endMinuteSelections") {{item}}
    div
      v-switch(v-model="allDay" label="終日")
    div.f.flex-right
      v-btn(@click="saveEvent" color="#1a73e8" dark) 保存
</template>

<style lang="scss" scoped>
.wrap-module-schedule-event {
  min-width: 320px;
  background: #fff;
  border-radius: 3px;
  .label {
    display: block;
    font-size: 12px;
    color: #999;
  }
  .input-date,
  .input-time {
    display: inline-block;
    color: #999;
    border-bottom: solid #999 1px;
  }
  .time {
    .selecter {
      border-bottom: solid #999 1px;
    }
    select {
      outline: none;
      color: #999;
    }
    span {
      color: #999;
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import ItemAutoCompleteSelector from '@/components/item/ItemAutoCompleteSelector'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemAutoCompleteSelector
  },
  props: {
    // option: {
    //   type: Object,
    //   required: true
    // }
  },
  data () {
    return {
      clients: [],
      selectedUser: null,

      title: '',
      comment: '',
      dateValue: new Date().toISOString().substr(0, 10),
      datePicker: new Date().toISOString().substr(0, 10),
      startHour: '07',
      startMinute: '00',
      endHour: '08',
      endMinute: '00',
      allDay: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    startHourSelections: () => {
      var hours = []
      for (var i = 0; i < 25; i++) {
        var num = String(i)
        if (num.length === 1) num = '0' + i
        hours.push(num)
      }
      return hours
    },
    startMinuteSelections: () => {
      var minutes = []
      for (var i = 0; i < 60; i++) {
        var num = String(i)
        if (num.length === 1) num = '0' + i
        minutes.push(num)
      }
      return minutes
    },
    endHourSelections: () => {
      var hours = []
      for (var i = 0; i < 25; i++) {
        var num = String(i)
        if (num.length === 1) num = '0' + i
        hours.push(num)
      }
      return hours
    },
    endMinuteSelections: () => {
      var minutes = []
      for (var i = 0; i < 60; i++) {
        var num = String(i)
        if (num.length === 1) num = '0' + i
        minutes.push(num)
      }
      return minutes
    }
  },
  async created () {
    this.clients = await db.collection('CLIENTS')
      .get()
      .then(q => {
        return q.docs.map(d => {
          return d.data()
        }).filter(client => {
          return client.authedClientUid
        })
      })

    if (this.$route.params.authedClientUid) {
      this.$refs.clientSelector.selectedUser = this.clients.filter(clientUser => {
        return clientUser.authedClientUid === this.$route.params.authedClientUid
      })[0]
      this.selectedUser = this.$refs.clientSelector.selectedUser
    }
  },
  watch: {
    datePicker (newDate) {
      this.dateValue = newDate
    }
  },
  methods: {
    ...mapActionsAuth([
      'checkAuthedClientExisting'
    ]),
    async saveEvent () {
      if (this.title === '') {
        alert('タイトルを入力してください。')
        return false
      }
      if (this.selectedUser === null) {
        alert('会員様を選択してください。')
        return false
      }

      var isClient = await this.checkAuthedClientExisting(this.uid)
      var createdUserType = (isClient) ? 'client' : 'trainer'
      var event = {
        title: this.title,
        date: this.dateValue,
        allDay: this.allDay,
        type: 'normal',
        createdUserType,
        createdBy: this.uid,
        authedClientUid: this.selectedUser.authedClientUid,
        created: new Date(),
        updated: new Date()
      }

      if (!this.allDay) {
        event.startHour = this.startHour
        event.startMinute = this.startMinute
        event.endHour = this.endHour
        event.endMinute = this.endMinute
      }

      if (this.comment) event.comment = this.comment

      // await db.collection('CLIENTS')
      //   .doc(this.option.clientId)
      //   .collection('EVENTS')
      //   .add(event)

      await db.collection('EVENTS')
        .add(event)

      this.$emit('closeModal')
    },
    callBackOnList () {
      this.selectedUser = this.$refs.clientSelector.selectedUser
    }
  }
}
</script>
