<template lang="pug">
  div.wrap-item-auto-complete-selector
    div.wrap-input.f.fm.px10.pb4
      div(v-if="selectedUser").wrap-img.mr8
        img(:src="selectedUser.photoURL")
      div(v-else).wrap-img.mr8
        div.icon-img.f.fh
          v-icon(size="20px") {{icon}}
      input(
        ref="input"
        :placeholder="placeholder"
        v-model="name"
        @focus="onInput"
        @blur="outInput")
      v-icon(color="#999" @click="$refs.input.focus()") arrow_drop_down
    div(v-if="showDropdown" @click.stop).drop-down
      div(v-for="item in allUsers" @click="onList(item)").list.f.fm.px10.py8.cursor-pointer
        div.wrap-img.f.fh.mr8
          img(:src="item.photoURL")
        span {{item.name}}

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-auto-complete-selector {
  position: relative;
  .wrap-input {
    border-bottom: $basic_border;
    height: 44px;
    .wrap-img {
      border-radius: 50%;
      overflow: hidden;
      width: 32px;
      height: 32px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .icon-img {
        border-radius: 50%;
        background: $active_color;
        overflow: hidden;
        width: 32px;
        height: 32px;
      }
    }
    input {
      width: calc(100% - 72px);
      outline: none;
    }
  }
  .drop-down {
    position: absolute;
    top: 36px;
    background: $primary_bg_color;
    overflow: scroll;
    width: 100%;
    max-height: 40vh;
    z-index: 101;
    .list {
      border-bottom: $secondary_border;
      .wrap-img {
        border-radius: 50%;
        overflow: hidden;
        width: 32px;
        height: 32px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    // data: {
    //   type: Object,
    //   default: ''
    // }
    user: {
      type: Object,
      required: false
    },
    allUsers: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      name: '',
      showDropdown: false,
      selectedUser: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    name: function (newVal) {
      this.options = this.allUsers.filter(e => {
        return (e.name.indexOf(newVal) !== -1)
      })
    },
    selectedUser: function () {
      this.name = this.selectedUser.name
    }
  },
  created () {
    if (this.user) {
      this.name = this.user.name
      this.selectedUser = this.user
    }
  },
  methods: {
    onInput () {
      this.options = this.allUsers.filter(e => {
        return true
      })
      this.showDropdown = true
    },
    outInput () {
      setTimeout(() => {
        this.showDropdown = false
      }, 200)
    },
    onList (option) {
      this.selectedUser = option
      this.name = this.selectedUser.name
      this.$emit('callBackOnList')
    }
  }
}
</script>
