<template lang="pug">
  div(:class="{'is-active': showDropdown}").wrap-module-select-client.py30.px40
    
    div.mb24
      ItemAutoCompleteSelector(
        ref="clientSelector"
        v-if="clients"
        :allUsers="clients"
        placeholder="会員様を選択"
        icon="mdi-account-box-multiple"
        @callBackOnList="callBackOnList")

    div.mb24
      ItemAutoCompleteSelector(
        ref="trainerSelector"
        v-if="clients && defaultTrainer && allTrainers"
        :allUsers="allTrainers"
        :user="defaultTrainer"
        placeholder="トレーナーを選択"
        icon="group"
        @callBackOnList="callBackOnList")

    //- div.wrap-auto-complete.mb24
      div.wrap-input.f.fm.px10.pb4
        div(v-if="selectedClient").wrap-img.mr8
          img(:src="selectedClient.photoURL")
        div(v-else).wrap-img.mr8
          //- img(src="/img/no-user-default.png")
          div.icon-img.f.fh
            v-icon(color="#fff" size="20px") mdi-account-box-multiple
        input(
          ref="input"
          placeholder="会員様を選択"
          v-model="name"
          @focus="onInput"
          @blur="outInput")
        v-icon(color="#2a2a2a" @click="$refs.input.focus()") arrow_drop_down
      div(v-if="showDropdown" @click.stop).drop-down
        div(v-for="item in options" @click="onList(item)").list.f.fm.px10.py8
          div.wrap-img.f.fh.mr8
            img(:src="item.photoURL")
          span {{item.name}}

    div.wrap-submit.f.flex-right
      span(@click="$emit('closeModal')").cancel.mr16 キャンセル
      span(@click="toRecord" :class="{'is-active': OKisActive}").save 決定

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-select-client {
  position: relative;
  min-width: 340px;
  background: $primary_bg_color;
  border-radius: 3px;
  top: 0;
  transition: all 200ms ease-out;
  &.is-active {
    top: -22vh;
  }
  .wrap-auto-complete {
    position: relative;
    .wrap-input {
      border-bottom: solid 0.8px #2a2a2a;
      height: 44px;
      .wrap-img {
        border-radius: 50%;
        overflow: hidden;
        width: 32px;
        height: 32px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        .icon-img {
          border-radius: 50%;
          background: #2a2a2a;
          overflow: hidden;
          width: 32px;
          height: 32px;
        }
      }
      input {
        width: calc(100% - 72px);
        outline: none;
      }
    }
    .drop-down {
      position: absolute;
      top: 36px;
      background: $primary_bg_color;
      overflow: scroll;
      width: 100%;
      max-height: 40vh;
      z-index: 101;
      .list {
        .wrap-img {
          border-radius: 50%;
          overflow: hidden;
          width: 32px;
          height: 32px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .wrap-submit {
    span {
      cursor: pointer;
    }
    .cancel {
      color: $secondary_text_color;
    }
    .save {
      color: $secondary_text_color;
      pointer-events: none;
      &.is-active {
        color: $active_color;
        pointer-events: all;
      }
    }
  }
}
</style>

<script>
import moment from 'moment'
import db from '@/components/utils/firebase'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateClient, mapActions: mapActionsClient } = createNamespacedHelpers('client')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

import ItemAutoCompleteSelector from '@/components/item/ItemAutoCompleteSelector'

export default {
  components: {
    ItemAutoCompleteSelector
  },
  props: {
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateClient(['clients'])
  },
  data () {
    return {
      options: [],
      name: "",
      selectedClient: null,

      OKisActive: false,
      showDropdown: false,
      allTrainers: null,
      defaultTrainer: null
    }
  },
  watch: {
    name: function (newVal) {
      this.options = this.clients.filter(e => {
        return (e.name.indexOf(newVal) !== -1)
      })
    },
    selectedClient: function (newObj) {
      this.OKisActive = (newObj)? true : false
    }
  },
  async created () {
    await this.loadClients()
    this.defaultTrainer = await db.collection("TRAINERS")
      .doc(this.uid).get().then(d => { return d.data() })
    this.allTrainers = await db.collection("TRAINERS")
      .get()
      .then(q => {
        return q.docs.map(d => {
          return d.data()
        })
      })
  },
  methods: {
    ...mapActionsClient(['loadClients']),
    ...mapActionsRecord([
      'getPreviousRecordOf',
      'createRecord'
    ]),
    onInput () {
      this.options = this.clients.filter(e => {
        return true
      })
      this.showDropdown = true
    },
    outInput () {
      setTimeout(() => {
        this.showDropdown = false
      }, 200)
    },
    onList (option) {
      this.selectedClient = option
      this.name = this.selectedClient.name
    },
    callBackOnList () {
      if (this.$refs.clientSelector.selectedUser
        && this.$refs.trainerSelector.selectedUser) {
        this.OKisActive = true
      }
    },
    async toRecord () {
      var headerComponent = this.$parent.$parent.$parent

      var targetClientId = this.$refs.clientSelector.selectedUser.uid // this.selectedClient.id
      
      switch (headerComponent.selectedRecordType) {
        case "karte":
          var previousRecord = await this.getPreviousRecordOf({
            created: new Date(),
            clientId: targetClientId,
            type: "karte"
          })

          // if (previousRecord) {
          //   var clientWeight = previousRecord.clientWeight
          // } else {
          //   var clientWeight = 40
          // }

          var clientWeight = (previousRecord && previousRecord.clientWeight)? previousRecord.clientWeight: 40
          var maxPressure = (previousRecord && previousRecord.maxPressure)? previousRecord.maxPressure: 0
          var minPressure = (previousRecord && previousRecord.minPressure)? previousRecord.minPressure: 0

          var recordObj = {
            label: moment(new Date()).format('YYYY年M月DD日'),
            memo: "",
            clientWeight: clientWeight,
            maxPressure: maxPressure,
            minPressure: minPressure,
            body: [],
            uid: this.$refs.trainerSelector.selectedUser.uid, //this.uid,
            clientId: targetClientId,
            type: "karte",
            trainingDate: new Date(),
            created: new Date(),
            updated: new Date()
          }
          
          var id = await this.createRecord(recordObj)
          
          this.$router.push(`/record/${targetClientId}`)
          this.$router.push(`/record/karte/${targetClientId}/${id}`)
          break

        case "consultation":
          var consultationObj = {
            label: "コンサルテーション " + moment(new Date()).format('YYYY年M月DD日'),
            goalThisMonth: "",
            goalLastMonth: "",
            mealMorning: "",
            mealLunch: "",
            mealDinner: "",
            mealOther: "",
            sleep: "",
            defecation: "",
            workout: "",
            other: "",
            uid: this.$refs.trainerSelector.selectedUser.uid, // this.uid,
            clientId: targetClientId,
            type: "consultation",
            created: new Date(),
            updated: new Date()
          }

          var id = await this.createRecord(consultationObj)

          this.$router.push(`/record/${targetClientId}`)
          this.$router.push(`/record/consultation/${targetClientId}/${id}`)
          break

        case "inbody":
          var inbodyObj = {
            label: "体組成計 " + moment(new Date()).format('YYYY年M月DD日'),
            uid: this.$refs.trainerSelector.selectedUser.uid, // this.uid,
            imgs: [],
            measuredAt: new Date(),
            clientId: targetClientId,
            type: "inbody",
            created: new Date(),
            updated: new Date()
          }

          var id = await this.createRecord(inbodyObj)

          this.$router.push(`/record/${targetClientId}`)
          this.$router.push(`/record/inbody/${targetClientId}/${id}`)
          break
      }

      headerComponent.closeModalWindow()
    }
  }
}
</script>
