<template lang="pug">
  div.wrap-module-client-buy-product.f.fh.px24.py24
    div(v-if="mode === 'type_card'")
      div(v-if="product").mb12
        span.name {{product.name}}
        span.price {{`${product.amount}円`}}
      div.wrap-card-form
        form(action="/charge" method="post" id="payment-form")
          div(class="form-row")
            label(for="card-element") カード番号
            div(id="card-element")
            div(id="card-errors" role="alert")
          div.f.fc
            button お支払い
    div(v-if="mode === 'now_loading'").wrap-loading.f.fh
      v-progress-circular(indeterminate color="#2a2a2a")
    div(v-if="mode === 'complete'").wrap-complete
      span 購入が完了しました！
      div.f.fc.mt20
        span(@click="onOK").button-ok.px18.py6 OK
</template>

<style lang="scss" scoped>
.wrap-module-client-buy-product {
  width: 300px;
  min-height: 200px;
  background: #fff;
  border-radius: 3px;
  .name {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
  .price {
    color: #999;
  }
  .wrap-card-form {
    #card-element {
      padding: 12px 0 2px 0;
      margin-bottom: 14px;
      border-bottom: solid 1px #999;
    }
    button {
      font-size: 12px !important;
      background: #1867c0;
      color: #fff;
      border-radius: 3px;
      padding: 6px 12px;
    }
  }
  .button-ok {
    cursor: pointer;
    background: #1867c0;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
  }
  .wrap-loading {
    width: 100%;
    height: 100%;
  }
}
</style>

<script>
import db, { stripeKey, api } from '@/components/utils/firebase'
const stripe = Stripe(stripeKey)

export default {
  components: {
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      product: null,
      mode: 'type_card'
    }
  },
  async created () {
    this.product = await db.collection('PRODUCTS')
      .doc(this.option.productId)
      .get()
      .then(d => {
        return d.data()
      })
  },
  mounted () {
    this.formMountTimer = setInterval(this.mountCardForm, 800)
  },
  methods: {
    mountCardForm () {
      var cardElement = document.getElementById('card-element')
      if (cardElement) {
        clearInterval(this.formMountTimer)
      } else {
        return false
      }

      var elements = stripe.elements()
      var style = {
        base: {
          fontSize: '16px',
          color: '#32325d'
        }
      }

      this.card = elements.create('card', { style: style })

      this.card.mount('#card-element')

      this.card.addEventListener('change', this.cardValidate)

      var form = document.getElementById('payment-form')
      form.addEventListener('submit', this.formSubmit)
    },
    cardValidate (event) {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    },
    async formSubmit () {
      event.preventDefault()
      var token = await stripe.createToken(this.card).then(function (result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
          return 'error'
        } else {
          // トークンをサーバに送信
          // this.stripeTokenHandler(result.token)
          return result.token
        }
      })

      this.mode = 'now_loading'

      if (token === 'error') {
        alert('クレジットカードが認証できませんでした。')
        this.mode = 'type_card'
      }

      var params = {
        token: token.id,
        clientId: this.option.clientId,
        productId: this.option.productId
      }

      const response = await fetch(`${api}/buyProduct`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(params)
      }).catch((reason) => {
        alert('購入に失敗しました。')
        this.mode = 'type_card'
      })

      await response.json()

      alert('購入が完了しました！')

      this.mode = 'complete'
    },
    onOK () {
      this.$emit('closeModal')
      this.mode = 'type_card'
    }
  }
}
</script>
