import { render, staticRenderFns } from "./ModuleAddScheduleEvent.vue?vue&type=template&id=24b9896c&scoped=true&lang=pug&"
import script from "./ModuleAddScheduleEvent.vue?vue&type=script&lang=js&"
export * from "./ModuleAddScheduleEvent.vue?vue&type=script&lang=js&"
import style0 from "./ModuleAddScheduleEvent.vue?vue&type=style&index=0&id=24b9896c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b9896c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VDatePicker,VMenu,VSwitch,VTextField,VTextarea})
