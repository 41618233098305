<template lang="pug">
  div.wrap-set-client.py40.px40
    div.f.fc.mb20
      ItemMediaUploader(
        v-if="existingImg"
        :imgId="docId"
        :existingImg="existingImg"
        categoryName="clientIcon"
        ref="mediaUploader")
    div.wrap-inputs.mb12
      v-text-field(v-model="name" label="氏名" color="#2a2a2a")
      // v-text-field(v-model="email" label="メールアドレス" color="#2a2a2a")
    div.wrap-submit.f.flex-right
      span(@click="$emit('closeModal')").cancel.mr16 キャンセル
      span(@click="saveTrainer" :class="{'is-active': saveIsActive}").save 保存

</template>

<style lang="scss" scoped>
.wrap-set-client {
  min-width: 340px;
  background: #fff;
  border-radius: 3px;
  .wrap-submit {
    span {
      cursor: pointer;
    }
    .cancel {
      color: #1976d2;
    }
    .save {
      color: #999;
      pointer-events: none;
      &.is-active {
        color: #1976d2;
        pointer-events: all;
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import ItemMediaUploader from '@/components/item/ItemMediaUploader.vue'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
// const { mapActions: mapActionsClient } = createNamespacedHelpers('client')

export default {
  components: {
    ItemMediaUploader
  },
  props: {
    // When edit existing client
    existingDocId: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    newDocId: () => {
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var autoId = ''
      for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return autoId
    }
  },
  watch: {
    name: function () {
      this.saveIsActive = (this.name !== '' && this.email !== '')
    },
    email: function () {
      this.saveIsActive = (this.name !== '' && this.email !== '')
    }
  },
  data () {
    return {
      trainer: null,
      docId: null,
      existingImg: null,
      name: '',
      email: '',
      saveIsActive: false
    }
  },
  async created () {
    this.docId = this.$route.params.trainerId

    this.trainer = await this.getUserByUid(this.$route.params.trainerId)
    this.existingImg = this.trainer.photoURL
    this.name = this.trainer.name
    this.email = this.trainer.email

    if (!this.existingImg) this.existingImg = '/img/default-user-icon.png'
  },
  methods: {
    ...mapActionsAuth(['getUserByUid', 'setUser']),
    async saveTrainer () {
      if (!this.saveIsActive) return
      this.saveIsActive = false
      var trainerObj = {
        uid: this.trainer.uid,
        name: this.name,
        email: this.email,
        photoURL: this.$refs.mediaUploader.getImgUrl(),
        created: this.trainer.created,
        lastSignInTime: this.trainer.lastSignInTime
      }

      await this.setUser(trainerObj)
      
      this.$parent.$parent.$parent.updateTrainerInfo()
      this.$emit('closeModal', trainerObj)
    }
  }
}
</script>
